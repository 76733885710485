

































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  components: {
    Customizer: () => import('@/layouts/common-drawer/Customizer.vue')
  },

  data() {
    return {
      layoutName: '',
      appLoading: false
    };
  },

  computed: {
    ...mapGetters(['getThemeMode']),
    ...mapGetters({ user: 'auth/getUser' }),
    ...mapGetters({ apps: 'altoleapApp/getAltoleapApps' })
  },

  methods: {
    ...mapActions(['changeThemeLayout', 'changeThemeLoadingState']),
    ...mapActions({
      autoRefresh: 'auth/autoRefresh',
      fetchUser: 'auth/fetchUser',
      fetchAltoleapApps: 'altoleapApp/fetchAltoleapApps',
      logout: 'auth/logout'
    })
  },

  async created() {
    try {
      this.changeThemeLoadingState(true);
      if (!this.user) {
        this.appLoading = true;
        await this.fetchAltoleapApps();
        await this.fetchUser();
      }
      this.autoRefresh();
    } catch (e) {
      // if app cannot fetch current user then logout
      // error does not have to be from api
      // wait for logout to be finished before we stop loading
      await this.logout();
    } finally {
      this.changeThemeLoadingState(false);
      this.appLoading = false;
    }
  }
});
